<template>
  <PageContainer>
    <template v-slot:header>
      <v-col class="d-flex">
        <v-btn icon size="40px" @click="goBack">
          <v-icon color="primaryblack2" size="24px">mdi-chevron-left</v-icon>
        </v-btn>
        <span class="heading-2"> {{ form.id ? "Update" : "Add" }} practitioner role </span>
      </v-col>
    </template>
    <v-card :loading="isLoading" class="organization-form">
      <v-col>
        <span class="heading-4 text-left">Role for {{ organization.name }} organization</span>
      </v-col>
      <v-divider />

      <v-col class="">
        <Select
          :items="practitioners"
          :label-active="true"
          :value="form.practitionerId"
          field-name="practitionerId"
          item-text="fullName"
          item-value="id"
          label="Practitioner that provide the defined services"
          @change="changeFormField"
        />
        <Select
          :items="healthcareServices"
          :label-active="true"
          :multiple="true"
          :value="form.healthcareServiceIds"
          field-name="healthcareServiceIds"
          item-text="name"
          item-value="id"
          label="Services provided by this worker"
          @change="changeFormField"
        />
        <Select
          :items="practitionerRoles"
          :label-active="true"
          :multiple="true"
          :value="form.codeIds"
          field-name="codeIds"
          item-text="display"
          item-value="code"
          label="Roles which this practitioner may perform"
          @change="changeFormField"
        />
      </v-col>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <PrimaryButton :loading="isSaving" text="Save" @click="performSave" />
      </v-card-actions>
    </v-card>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { PractitionerRoleApi } from "@/api/practitionerRole";
import PageContainer from "@/components/uikit/PageContainer.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Select from "@/components/uikit/Select.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { useOrganizationStore } from "@/pinia-store/organization";
import { usePractitionerRoleStore } from "@/pinia-store/practitionerRole";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "OrganizationEdit",
  data() {
    return {
      isLoading: false,
    };
  },
  components: { PrimaryButton, PageContainer, Select },
  computed: {
    ...mapState(useTerminologyStore, ["practitionerRoles"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    ...mapState(useOrganizationStore, ["organization"]),
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapState(usePractitionerRoleStore, ["form", "isSaving"]),
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitioners"]),
    ...mapActions(usePractitionerRoleStore, ["saveForm", "setForm", "changeFormField"]),
    ...mapActions(useTerminologyStore, ["preloadPractitionerRoles"]),
    ...mapActions(useOrganizationStore, ["getOrganization"]),
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    async performSave() {
      this.changeFormField(this.$route.params.organizationId, "organizationId");
      await this.saveForm();
    },
    goBack() {
      this.$router.push({
        name: routesEnum.organizationPractitioners,
        organizationId: this.$route.params.organizationId,
      });
    },
  },
  async mounted() {
    await Promise.all([this.preloadPractitionerRoles(), this.getPractitioners()]);
    if (this.$route.params.organizationId) {
      this.isLoading = true;
      this.getOrganization(this.$route.params.organizationId);
      this.getHealthcareServices(this.$route.params.organizationId);
      this.isLoading = false;
    }
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await PractitionerRoleApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }
    console.log("Organization mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}
</style>
